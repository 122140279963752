// src/router/otherRoutes.js

const otherRoutes = {
  path: '/other',
  component: () => import('@/components/other'),
  children: [
    {
      path: '/careers',
      name: 'Careers',
      component: () => import('@/pages/other/careers'),
      meta: {
        title: 'Careers | Sentri Cloud - Enabling AWS Cloud Architects',
      }
    },
    {
      path: '/waitlist-success',
      name: 'Wait List',
      component: () => import('@/pages/other/waitlist'),
      meta: {
        title: 'Wait List Successful | Sentri Cloud - Enabling AWS Cloud Architects',
      }
    },
  ]
};

export default otherRoutes;