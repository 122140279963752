// src/router/legalRoutes.js

const legalRoutes = {
  path: '/legal',
  component: () => import('@/components/legal'),
  children: [
    {
      path: '/cookies',
      name: 'Cookies',
      component: () => import('@/pages/legal/cookies'),
      meta: {
        title: 'Cookies Statement | Sentri Cloud - Enabling AWS Cloud Architects',
      }
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('@/pages/legal/privacy'),
      meta: {
        title: 'Privacy Policy | Sentri Cloud - Enabling AWS Cloud Architects',
      }
    },
    {
      path: '/terms',
      name: 'Terms',
      component: () => import('@/pages/legal/terms'),
      meta: {
        title: 'Terms & Conditions | Sentri Cloud - Enabling AWS Cloud Architects',
      }
    },
    {
      path: '/security',
      name: 'Security',
      component: () => import('@/pages/legal/security'),
      meta: {
        title: 'Security | Sentri Cloud - Enabling AWS Cloud Architects',
      }
    },
    {
      path: '/policies',
      name: 'Policy Testing',
      component: () => import('@/pages/legal/policies'),
      meta: {
        title: 'Policy Testing | Sentri Cloud - Enabling AWS Cloud Architects',
      }
    },
  ]
};

export default legalRoutes;