import { createApp } from 'vue'
// CORE APP:
import App from '@/App.vue'
import router from '@/router';

// STYLING:
import VueFeather from 'vue-feather';
import '@/assets/scss/style.scss'

// import "@/assets/css/vendors/swiper/swiper.min.css";
// import "@/assets/css/vendors/swiper/swiper-bundle.min.css";
import "@/assets/css/vendors/fontawesome/all.css";
// import "@/assets/css/vendors/animate.css";
// import "@/assets/css/style.css";

// SCRIPTS:
// import "@/assets/js/vendors/swiper/swiper-bundle.min.js";
// import "@/assets/js/custom-swiper.js";
import "@/assets/js/vendors/fontawesome/all.min.js";
import "@/assets/js/vendors/bootstrap.bundle.min.js";
// import "@/assets/js/script.js";


// PLUGINS:
import Hotjar from '@hotjar/browser';
const siteId = 3866377;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);


createApp(App)
  .use(router)
  .component(VueFeather.name, VueFeather)
  .mount('#app')

// TODO: Remove all warnings without needing suppression
//console.warn = function() {};