import {createRouter, createWebHistory} from "vue-router"
import errorRoutes from './errorRoutes';
import legalRoutes from './legalRoutes';
import otherRoutes from './otherRoutes';
const routes = [
  errorRoutes,
  legalRoutes,
  otherRoutes,
  {
    path: '/',
    component: () => import('@/components/main'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/pages/home/home'),
        meta: {
          title: 'Sentri Cloud - Enabling AWS Cloud Architects',
        }
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.beforeEach((to, from, next) => {
  if(to.meta.title) {
    document.title = to.meta.title;
  }
  return next();
});
export default router


// const router = createRouter({
//   history: createWebHistory(),
//   routes,
// })
// router.beforeEach((to, from, next) => {
//   if(to.meta.title)
//     document.title = to.meta.title;
//   const  path = ['/auth/login','/auth/register'];
//    if(path.includes(to.path) || localStorage.getItem('User')){
//     return next();
//    }
//    next('/auth/login');
// });
// export default router